import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Head from 'next/head';
import { connect } from 'react-redux';
import { Transition, TransitionGroup } from 'react-transition-group';

import { ScrollContainer } from 'components/ui/AppLayout';

import Question from './Question';
import Result from './Result';
import Footer from './Footer';

import styles from './Contact.scss';

class Contact extends React.Component {
    static getInitialProps = ({ store }) => {
        const state = store.getState();
        return {
            contentTypeId: state.pages['/contact'].contentTypeId,
        };
    };

    static propTypes = {
        // Component props from App.js
        isTouch: PropTypes.bool.isRequired,
        // Global pageProps from App.js getInitialProps
        contact: PropTypes.shape({
            quizzes: PropTypes.array,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            street: PropTypes.string.isRequired,
        }),
        // Component-specific props from getInitialProps
        contentTypeId: PropTypes.string.isRequired,
    };

    state = {
        showResult: false,
        selected: 0,
        quizId: 0,
    };

    componentDidMount() {
        this.setRandomQuiz();
    }

    setRandomQuiz = () => {
        const { quizzes } = this.props.contact;
        const quizId = Math.floor(Math.random() * quizzes.length);

        this.setState({ quizId });
    };

    handleClickAnswer = index => () => {
        this.setState({
            showResult: true,
            selected: index,
        });
    };

    handleClickClose = () => {
        this.setState(({ quizId }) => {
            const { quizzes } = this.props.contact;

            return {
                showResult: false,
                quizId: quizId < quizzes.length - 1 ? quizId + 1 : 0,
            };
        });
    };

    handleChangeResultVideo = index => {
        if (this.state.selected !== index) {
            this.setState({ selected: index });
        }
    };

    render() {
        const { showResult, selected, quizId } = this.state;
        const { contact, isTouch } = this.props;
        const { quizzes, street, city, email, phone } = contact;

        const currentView = showResult ? (
            <Result
                isTouch={isTouch}
                answers={quizzes[quizId].options}
                selectedAnswer={selected}
                onClickClose={this.handleClickClose}
                onChangeResultVideo={this.handleChangeResultVideo}
            />
        ) : (
            <Question
                isTouch={isTouch}
                question={quizzes[quizId].options}
                onClickAnswer={this.handleClickAnswer}
            />
        );

        const transitionKey = showResult ? 'result' : 'question';

        const className = cx(styles.contactTransition, {
            [styles.portal]: showResult && !isTouch,
        });

        return (
            <ScrollContainer>
                <Head>
                    <title>Contact | HAUS</title>
                </Head>
                <TransitionGroup component="section" className={className}>
                    <Transition timeout={800} key={transitionKey}>
                        {state => (
                            <div className={cx(styles.contact, styles[state])}>
                                {currentView}
                                {!showResult && (
                                    <Footer
                                        street={street}
                                        city={city}
                                        email={email}
                                        phone={phone}
                                    />
                                )}
                            </div>
                        )}
                    </Transition>
                </TransitionGroup>
            </ScrollContainer>
        );
    }
}

export default connect()(Contact);
